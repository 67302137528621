
            @import 'src/styles/mixins.scss';
        

@import '@styles/sassVariables';
.border{
    border-bottom: 4px solid $antivirus-choice-gray-light;
}
.antivirus-gray-bg{
    position: relative;
    &::before{
        content : "";
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        height: 100%;
        width: 100vw;
        transform: translate(-50%, -50%);
        background: #fafafa;
    }
}