
            @import 'src/styles/mixins.scss';
        
.question {
    max-width: 70%;
}
.answer {
    transition-property: all;
    transition-duration: 250ms;
}
.border-bottom{
    border-bottom: 2px solid #e5e7eb
}
.border{
    border: 2px solid #e5e7eb
}